@import '../../assets/scss/variables';

.Welcome {

  & .welcome-title {
    margin-top: 3rem;
    font-size: $text-size-sm;
  }

  & .welcome-content {
    margin-top: 8rem;
  }

  & .welcome-content>p {
    margin-bottom: 1rem;
  }

  & .welcome-why-myfamilyrecipe {
    margin-bottom: 2.5rem;
    font-size: larger;
  }
}



@media screen and ( min-width: $screen-sm-min ) {
  .Welcome {

    & .welcome-title {
      margin-top: 7rem;
    }

    & .welcome-message {
    }

    & p {
    }
  }
}


@media screen and ( min-width: $screen-md-min ) {
  .Welcome {

    & .welcome-title {
      margin-top: 10rem;
      font-size: $text-size-md;
    }

    & .welcome-why-myfamilyrecipe {
      font-size: x-large;
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .Welcome {

    & .welcome-title {
      margin-top: 12rem;
    }
  }
}