@import '../../assets/scss/variables';

.Register {
  width: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  & .register-title {
    margin-bottom: 1rem;
  }

  & .login-title {
    font-size: $text-size-sm;
  }

  & .login-form {
    margin-top: 2rem;
  }

  & .formInput-container {
    display: grid;
    grid-template-columns: 6% 80% 10%;
    grid-row: 2;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  & .formInput-container>label {
    grid-area: 1/2/1/3;
    text-align: start;
  }

  & .valid-input {
    grid-area: 2/1/2/1;
    transform: scale(2.5);
    color: #7CCD5F;
    align-self: center;
  }

  & .formInput-container>input {
    grid-area: 2/2/2/2;
    height: 2rem;
    border-radius: 7px;
    border: none;
    padding-left: .5rem;
  }

  & .input-eyes {
    grid-area: 2/3/2/3;
    justify-self: center;
    align-self: center;
    transform: scale3d(1.6,1.6,1.6);
  }

  & .buttons {
    display: grid;
    grid-template-columns: 45% 45% 10%;
    height: 2rem;
    margin-top: 1.5rem;
  }

  & .buttons>input {
    width: 90%;
    max-width: 200px;
    height: 100%;
    margin: 0 auto;
  }

  & .message {
    margin: auto;
    margin-top: 1rem;
    width: fit-content;
    padding: .2rem;
  }

}


@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) {
  .Register {

    & .register-title {
      font-size: $text-size-md;
    }

    & .register-form {
      margin-top: 2.5rem;
    }

    & .formInput-container {
    }

    & .valid-input {
    }
    & .message {
      font-size: x-large;
    }
  }



}

@media screen and ( min-width: $screen-md-min ) {
  .Register {

    &.register-form {
      margin-top: 4rem;
    }

    & .formInput-container {
      gap: .5rem;
      margin-bottom: 2.5rem;
    }

    & .valid-input {
      justify-self: center;
    }

    & .Register>h1 {
      font-size: $text-size-md;
    }

    & .buttons {
      height: 2.5rem;
      margin-top: 1.5rem;
    }
  }



}

@media screen and ( min-width: $screen-lg-min ) {
  .Register {
    width: 80%;
    margin: 0 auto;
    height: 100%;

    & .register-form {
      width: 80%;
      margin: 5rem auto;
    }

    & .formInput-container {
      display: grid;
      grid-template-columns: 20% 6% 60% 7%;
      grid-row: 1;
      gap: 1rem;
      margin-bottom: 2.5rem;
    }

    & .formInput-container>label {
      grid-area: 1/1/1/1;
      text-align: start;
      padding-left: 2rem;
      align-self: center;
    }

    & .valid-input {
      grid-area: 1/2/1/2;
      justify-self: center;
    }

    & .formInput-container>input {
      grid-area: 1/3/1/3;
      height: 2rem;
      border-radius: 7px;
      border: none;
      padding-left: .5rem;
    }

    & .input-eyes {
      grid-area: 1/4/1/4;
      justify-self: flex-start;
      align-self: center;
      transform: scale3d(1.6,1.6,1.6);
    }

    & .buttons {
      margin-top: 6rem;
    }
  }

}

@media screen and ( min-width: $screen-lg-min ) {
  .Register {
    width: 80%;
    margin: 0 auto;
    height: 100%;

    & .register-form {
      width: 80%;
      margin: 6rem auto;
    }

    & .formInput-container {
      display: grid;
      grid-template-columns: 20% 6% 60% 7%;
      grid-row: 1;
      gap: 1rem;
      margin-bottom: 2.5rem;
    }

    & .formInput-container>label {
      grid-area: 1/1/1/1;
      text-align: start;
      padding-left: 2rem;
      align-self: center;
    }

    & .valid-input {
      grid-area: 1/2/1/2;
      justify-self: center;
    }

    & .formInput-container>input {
      grid-area: 1/3/1/3;
      height: 2rem;
      border-radius: 7px;
      border: none;
      padding-left: .5rem;
    }

    & .input-eyes {
      grid-area: 1/4/1/4;
      justify-self: flex-start;
      align-self: center;
      transform: scale3d(1.6,1.6,1.6);
    }

    & .buttons {
      margin-top: 6rem;
      grid-template-columns: 45% 45%;
      justify-content: space-evenly;
      align-items: center;


    }
  }

}

@media screen and ( min-width: $screen-xl-min ) {
  .Register {


    & .register-form {
    }

    & .formInput-container {
      grid-template-columns: 20% 6% 390px 7%;
      gap: 1rem;
      margin-bottom: 2rem;
    }

    & .formInput-container>label {


    }

    & .valid-input {


    }

    & .formInput-container>input {
      max-width: 390px;

    }

    & .input-eyes {


    }

    & .buttons {

    }
  }

}
