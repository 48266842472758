@import '../../assets/scss/variables';

.NotFound {
  & h1 {
    margin-top: 4rem;
    font-size: 4rem;
  }
  & p {
    margin: 7rem 0 2rem 0;
  }
}

@media screen and ( max-width: $screen-md-min ) {
  .NotFound {
    & h1 {
    }

    & p {
    }
  }
}

@media screen and (min-width: $screen-sm-min) and (min-height: $screen-sm-min) {
  .NotFound {
    & h1 {
      margin-top: 8rem;
      font-size: 6rem;
    }
    & p {
      margin: 7rem 0 2rem 0;
    }
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .NotFound {
    & h1 {
    }
    & p {
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .NotFound {
    & h1 {
      margin-top: 10rem;
      font-size: 6rem;
    }
    & p {
      margin: 7rem 0 2rem 0;
    }
  }
}

@media screen and ( min-width: $screen-xl-min ) {
  .NotFound {
    & h1 {
      margin-top: 12rem;
      font-size: 6rem;
    }
    & p {
    }
  }
}
