@import '../../assets/scss/variables';

.AskPasswordReset {

  & .AskPasswordReset-title {
    font-size: $text-size-sm;
  }


  & .AskPasswordReset-form {
    margin: 1rem 0 4rem 0;
  }

  & .formInput-container {
    @include flex( flex-start, flex-start, column nowrap);
    margin: 3rem 0;

  }

  & .formInput-container>label {
    margin-bottom: 1rem;
  }

  & .formInput-container>input {
    height: 2rem;
    width: 90%;
    padding-left: .5rem;
    align-self: center;
  }

  & .buttons {
    @include flex( center, space-evenly, row nowrap);
  }

  & .buttons>input {
    width: 45%;
    height: 1.5rem;
  }

  & .register-message {
  }

  & .ask-password-reset {
    margin-bottom: 3rem;
  }
}




@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) {
  .AskPasswordReset {

    & .AskPasswordReset-title {
      font-size: $text-size-md;
    }

    & .AskPasswordReset-form {
      margin: 1rem 0 4rem 0;
    }

    & .formInput-container {


    }

    & .buttons {
    }
  }


}

@media screen and ( min-width: $screen-md-min ) {
  .AskPasswordReset {

    & .AskPasswordReset-form {
      margin: 2rem 0;
    }

    & .formInput-container {
      display: grid;
      grid-template-columns: 20% 75%;
      margin: 7rem 0;
      align-items: center;

      & label {
        margin-bottom: 0;
      }
    }
  }


}

@media screen and ( min-width: $screen-lg-min ) {
  .AskPasswordReset {

    & .AskPasswordReset-title {
    }

    & form.AskPasswordReset-form {
      font-size: 1.2rem;
    }

    & .formInput-container {
    }

    & .buttons {
    }
  }


}


