@import 'assets/scss/variables';

.Header {

  & .menu-bar{
  }

  & .title {
  }

  & .user-name .user-log{
  }

  & .infos-user {
  }
}




@media screen and ( max-width: $screen-md-min ) {
  .Header {

    & .hide {
    }

    & div.hide-component {
    }
  }

}

@media screen and (min-width: $screen-sm-min) {
  .Header {
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .Header {

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .Header {
    @include flex( flex-start, space-between, row nowrap);
    width: 90%;
    height: 3.5rem;
    background: $sub-component-bg-color;
    align-items: center;
    border-radius: 7px;
    padding: 2rem 1rem;

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}


