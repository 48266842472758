@import '../../assets/scss/variables';

.AddNewRecipe {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @include flex( center, center, row wrap);
  border-radius: 7px;
  height: auto;
  width: 100%;
  margin-bottom: 1rem;

  & .card {
    width: 95%;
    border-radius: 7px;

    & .spacer {
      border-bottom: 1px solid $background-color;
      padding-bottom: .5rem;
      margin-bottom: .5rem;
    }

    & .recipe-title {
      margin: .5rem;
      padding: .5rem;
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 7px;

      & #name {
        width: 100%;
        margin: auto;
      }
    }

    & .recipe-description {
      margin: .5rem;
      padding: .5rem;

      & #description {
        width: 100%;
        margin: auto;
      }
    }

    & .recipe-infos {
      margin: .5rem;
      padding: .5rem;
      display: grid;
      grid-template-columns: 30% 30% 30%;
      grid-gap: 0.5rem;


      & #recipe_preparation_time {
        width: 100%;
        margin: auto;
      }

      & .recipe-tag {
        grid-area: 2/1/2/4;
      }

      & span {
        border-radius: 7px 0 0 7px;
      }

      & input, select {
        border-radius: 0 7px 7px 0;
      }
    }
  }

  & .card-icons {
    width: 20px;
  }

  & #Ingredients-tabpane-ingredients, #Ingredients-tabpane-instructions {
    min-height: 8rem;
  }

  & .tab-content {
    width: 100%;
    margin: .5rem 0 .2rem 0;
    min-height: 2rem;
  }

  & .ingredient {
    margin-bottom: .5rem;
    display: grid;
    grid-template-columns: 28% 28% 28% 8% 8%;

    & .add-ingredient {
      align-self: center;
      justify-self: center;
      transform: scale(1.5);
      grid-area: 1/4/1/4;
    }

    & .submit-change {
      grid-area: 1/4/1/4;
    }

    & .cancel-change {
      grid-area: 1/5/1/5;
      justify-self: center;
      align-self: center;
      transform: scale(1.5);
    }

    & .ingredient-trash {
      grid-area: 1/5/1/5;
      align-self: center;
      justify-self: center;
    }

    & .edit-ingredient-quantity {
      text-align-last: end;
    }

    & .ingredient-name, .ingredient-unit {
      justify-self: left;
      padding-left: .5rem;
    }

    & .ingredient-name {
      grid-area: 1/1/1/1;
    }

    & .ingredient-unit {
      grid-area: 1/3/1/3;
    }

    & .ingredient-quantity {
      grid-area: 1/2/1/2;
      justify-self: right;

      & .edit-ingredient-quantity>input {
        width: 70%;
      }
    }
  }

  & .instruction {
    margin-bottom: .5rem;
    display: grid;
    grid-template-columns: 7% 77% 7% 7%;
    grid-gap: .2rem;

    & .add-instruction {
      grid-area: 1/3/1/3;
      transform: scale(1.5);
      justify-self: center;
      align-self: center;
    }

    & .cancel-change {
      grid-area: 1/4/1/4;
      transform: scale(1.5);
      justify-self: center;
      align-self: center;
    }

    & .instruction-description {
      grid-area: 1/2/1/2;
    }

    & #instruction_description {
      width: 90%;
      padding-left: .5rem;
    }

    & .instruction-trash {
      align-self: center;
      justify-self: center;
      grid-area: 1/4/1/4;
    }
  }

  & nav>a.nav-item {
    color: $text-color;
    text-decoration: none;
  }

  & input, select, textarea {
    background: #ddd;
  }

  & .dragndrop {
    align-self: center;
    justify-self: center;
    width: 20px;
    //transform: scale(1.2);
    grid-area: 1/1/1/1;
    //transform: rotate(90deg);
  }

  & .buttons {
    @include flex( center, space-evenly, row nowrap);
    padding: .5rem 0;

    & button {
      height: 2rem;
      width: 45%;
      border: none;
      border-radius: 7px;
      max-width: 200px;
    }
  }

}


div.modal-dialog {

  & input, select, textarea {
    background: #ddd;
  }

  & div.add-ingredient-modal-body, .add-instruction-modal-body {
    @include flex( center, space-evenly, row nowrap);

    & * {
      width: 45%;
    }
  }
}


@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) and (max-height: $screen-md-min){
  .AddNewRecipe {

    & .one-recipe-card {
      margin-bottom: 3rem;
    }
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .modal {
    width: 600px;
    height: 450px;
  }

  .AddNewRecipe {

    svg {
      width: 20px;
    }

    & .card {

      & .recipe-title {

        & #name {
          width: 65%;
        }
      }

      & .recipe-description {

        & #description {
          width: 75%;
        }
      }

      & .recipe-infos {
        margin: .5rem;
        padding: .5rem;
        display: grid;
        grid-template-columns: 45% 45%;
        grid-gap: 0.5rem;

        & #recipe_preparation_time {
          width: 30%;
          margin: auto auto auto 0;
          grid-area: 1/1/1/1;
        }

        & #recipe_cooking_time {
          width: 30%;
          margin: auto auto auto 0;
          grid-area: 1/2/1/2;
        }

        & #part_number {
          width: 30%;
          margin: auto auto auto 0;
          grid-area: 2/1/2/1;
        }

        & .recipe-tag {
          grid-area: 2/2/2/2;
        }
      }

    }

    & .tab-content {
      width: 90%;
      margin: 1rem auto 1rem auto;
      min-height: 2rem;
    }

    & .ingredient {
      display: grid;
      grid-template-columns: 28% 28% 28% 8% 8%;

      & * {
        align-self: center;
      }

      & .ingredient-trash {
        grid-area: 1/5/1/5;
      }

      & .ingredient-name {
        grid-area: 1/1/1/1;
        padding-left: 0;
        align-self: center;
      }

      & .ingredient-quantity {
        grid-area: 1/2/1/2;
        justify-self: right;
        padding-right: 4rem;
      }

      & .ingredient-unit {
        grid-area: 1/3/1/3;
      }
    }

    & .instructions-list {
    }

    & .instruction {


      & .instruction-description {

        & #instruction_description {
          width: 95%;
        }
      }
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  div.modal {
    top: 15%;
    left: 22%;
  }

  .AddNewRecipe {
    width: 90%;
    max-height: 100%;
    margin-bottom: 0;

    & .card {
      width: 95%;
      border-radius: 7px;

      & .recipe-infos {
        margin-left: 5rem;

        & #recipe_preparation_time {
        }

        & #recipe_cooking_time {
        }

        & #part_number {
        }

        & .recipe-tag {
        }

        & .input-group-prepend {

          & .input-group-text {
          }
          & input, select {
          }

        }

        & input {

        }
      }

      & .form-group {
      }

      & .recipe-title {
        margin: .5rem;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-radius: 7px;

        & #name {
          width: 40%;
          margin: auto;
        }

        & label {
          display: none;
        }
      }

      & .recipe-description {
        margin: .5rem;
        padding: 1rem;

        & #description {
          width: 50%;
          margin: auto;
        }


        & label {
          display: none;
        }
      }
    }


    & nav.nav {
      display: none;
    }

    & #recipe_preparation_time, #recipe_cooking_time, #part_number, #tag_name {
      width: 15%;
    }

    & .ingredient {

      & .ingredient-quantity {
        justify-self: center;
        padding-right: 0;
      }
    }

    & div.tab-content {
      display: grid;
      grid-template-columns: 50% 50%;
      //height: 100%;
      width: 90%;

      & #Ingredients-tabpane-ingredients {
        opacity: 1;
        grid-area: 1/1/1/1;
        padding: .5rem 1rem;
        border-right: 1px solid $background-color;

        & .ingredient {
          grid-template-columns: 28% 28% 28% 8% 8%;
        }


      }

      & #Ingredients-tabpane-instructions {
        opacity: 1;
        display: block;
        grid-area: 1/2/1/2;
        padding: .5rem;
        border-left: 1px solid $background-color;

      }

      & .active {
        font-weight: normal;
      }
    }

    & .card-info-tag {
      padding-left: 1rem;
    }

    & .card-infos-value {
      padding-left: .5rem;
    }

    & #tag_name {
      width: auto;
    }

    & .instructions-list {
      padding-left: 1rem;
      padding-top: 0;
    }

    & .instruction {
      padding-left: 2rem;
      margin-bottom: 0.5rem;

    }

    & #instruction_description {
      width: 100%;
    }

    & #quantity {
      min-width: 4rem;
    }
  }
}

@media screen and ( min-width: $screen-xl-min ) {
  div.modal {
    top: 15%;
    left: 22%;
  }

  .AddNewRecipe {
    width: 90%;

    & .ingredient {

      & .ingredient-name {
        //padding-left: 3rem;
      }
    }
  }
}

@media screen and ( min-width: $screen-xxl-min ) {
  div.modal {
    top: 15%;
    left: 35%;
  }

  .AddNewRecipe {
    width: 90%;

    & .ingredient {

      & .ingredient-name {
        //padding-left: 3rem;
      }
    }
  }
}

