@import '../../assets/scss/variables';

.SearchBar {
  width: 100%;

  & .input-container {
    display: grid;
    grid-template-columns: 95% 5%;
    grid-row: 2;
    margin-bottom: .5rem;
  }

  & .searchBar-input {
    width: 95%;
    height: 2rem;
    padding-left: .5rem;
  }

  & .cancel-button {

  }

  & .options {
    margin-top: .5rem;
  }

  & .options-button {
    align-self: center;
    vertical-align: -webkit-baseline-middle;
    transform: scale(1.5);
  }

  & .option-checkbox-container {
    grid-area: 2/1/2/1;
    @include flex( center, center, row wrap);
  }

  & .option-tags-checkbox {
    @include flex( center, center, column nowrap);
    margin: 0 .7rem;
  }

  & .search-label {
    margin-bottom: .2rem;
  }

  & .searchButton {
    display: none;
  }
}


@media screen and ( max-width: $screen-md-min ) {
  .SearchBar {

    & .hide {
    }
  }

}

@media screen and (min-width: $screen-sm-min) {
  .SearchBar {
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .SearchBar {

    & .user-name .user-log{
    }

    & .option-checkbox-container{
    }

    & .options {
    }

    & .option-tags-checkbox {
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {

  .SearchBar {

    & .input-container {
      position: absolute;
      top: 60%;
      right: 11%;
      transform:  translate(-0%,50%);
      border-radius: 40px;
      padding: 10px;
      text-decoration-color: white;
      @include flex( center, center, row nowrap);
      height: 3.5rem;
      background: $sub-component-bg-color;
    }

    & .input-container >.searchBar-input:focus {
      width: 240px;
      padding: 0 6px;

    }

    & .searchButton {
      display: flex;
      color: white;
      float: right;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $component-bg-color;
      justify-content: center;
      align-items: center;
      transition: 0.4s;
      padding: .25rem;
    }

    & .searchBar-input {
      border:none;
      background: none;
      outline:none;
      float:left;
      padding: 0;
      font-size: 16px;
      transition: 0.4s;
      line-height: 40px;
      width: 0;
      height: 100%;
      mix-blend-mode: color-burn;
      margin: 0.1rem 0;
    }

    & .options {
      display: none;
      width: 0;
      height: 100%;
      position: relative;
      margin-top: 0;
    }

    & .option-show {
      @include flex( center, center, column nowrap);
    }

    & .options-button {
      display: none;
      align-self: center;
      vertical-align: -webkit-baseline-middle;
      transform: scale(1.5);
    }

    & .option-checkbox-container {
      position: absolute;
      top: 3rem;
      width: 10rem;
      padding: .5rem;
      border-radius: 7px;
      background: $sub-component-bg-color;
    }

    & .option-tags-checkbox {
      width: 100%;
      display: grid;
      grid-template-columns: 10% 90%;
      grid-gap: .5rem;

      & .search-label {
        grid-area: 1/2/1/2;
      }

      & .search-input {
        grid-area: 1/1/1/1;
      }
    }

    & .user-name .user-log{
    }

    & .option-checkbox-container{
    }


    & .option-tags-checkbox {
    }
  }

  .SearchBar:hover > .input-container >.searchBar-input {
    width: 240px;
    padding: 0 6px;
  }

  .SearchBar:hover > .input-container >.options, .SearchBar:hover > .input-container >.options-button{
    @include flex( center, center, column nowrap);
    height: 70%;
  }
}

@media screen and ( min-width: $screen-xl-min ) {

  .SearchBar {

    & .input-container {
      right: 10%;
    }

    & .input-container >.searchBar-input:focus {


    }

    & .searchButton {

    }

    & .searchBar-input {

    }

    & .options {

    }

    & .option-show {

    }

    & .options-button {

    }

    & .option-checkbox-container {

    }

    & .option-tags-checkbox {


      & .search-label {

      }

      & .search-input {

      }
    }

    & .user-name .user-log{
    }

    & .option-checkbox-container{
    }


    & .option-tags-checkbox {
    }
  }

  .SearchBar:hover > .input-container >.searchBar-input {

  }

  .SearchBar:hover > .input-container >.options, .SearchBar:hover > .input-container >.options-button{

  }
}


@media screen and ( min-width: $screen-xxl-min ) {

  .SearchBar {

    & .input-container {
      right: 8.5%;
    }

    & .input-container >.searchBar-input:focus {

    }

    & .searchButton {

    }

    & .searchBar-input {

    }

    & .options {

    }

    & .option-show {

    }

    & .options-button {

    }

    & .option-checkbox-container {

    }

    & .option-tags-checkbox {


      & .search-label {

      }

      & .search-input {

      }
    }

    & .user-name .user-log{
    }

    & .option-checkbox-container{
    }


    & .option-tags-checkbox {
    }
  }

  .SearchBar:hover > .input-container >.searchBar-input {

  }

  .SearchBar:hover > .input-container >.options, .SearchBar:hover > .input-container >.options-button{

  }
}