@import '../../assets/scss/variables';

.UploadPictures {

  & .form_button {
    margin-top: 1rem;
  }
  & .imgPreview {
    margin-top: 1rem;
  }
  & .preview-img {
    width: 300px;
  }
}



