@import '../../assets/scss/variables';

.UserInfos {
  @include flex( flex-start, space-between, row  nowrap);

  & .menu-bar{
  }

  & .title {
  }

  & .user-name .user-log{
  }

  & .user-account, .user-logout, .user-login {
    padding: .2rem;
    border: 1px solid #eee;
    border-radius: 7px;
    color: $text-color;
    text-decoration: none;
    background-color: #ddd0;
  }

  & .user-login {
    margin-left: auto;
  }

}


@media screen and ( max-width: $screen-md-min ) {
  .UserInfos {

    & .hide {
    }

    & div.hide-component {
    }
  }

}

@media screen and (min-width: $screen-sm-min) {
  .UserInfos {
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .UserInfos {

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .UserInfos {
    @include flex( flex-end, space-around, column nowrap);
    min-width: 21%;
    font-size: .9rem;
    height: 3.3rem;
    background: $component-bg-color;
    padding-right: 1rem;
    border-radius: 7px;

    & .user-log{
    }

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}

@media screen and ( min-width: $screen-xl-min ) {
  .UserInfos {
    min-width: 18%;

    & .user-log{
    }

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}

@media screen and ( min-width: $screen-xxl-min ) {
  .UserInfos {
    min-width: 12%;

    & .user-log{
    }

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}