@import '../../assets/scss/variables';

.RecipesList {
  @include flex( center, center, row wrap);
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */





  & div>a.recipe-link {
    color: $text-color;
    text-decoration: none;
  }

  & .RecipesList::-webkit-scrollbar {
    display: none;
  }

  & a.recipe-link {
    color: $text-color;
    text-decoration: none;
  }

  & .recipe-card {
    width: 20rem;
    margin-bottom: .5rem;
  }

  & .card-body {
    display: grid;
    grid-template-columns: 20% 40% 40%;

    & img.img-thumbnail {
      grid-area: 1/1/1/1;
      margin-bottom: 1rem;
    }
  }

  & h3.recipe-card-header {
    margin-bottom: 1rem;
    grid-area: 1/2/1/4;
    height: 78%;
    padding: 1.4rem;
  }

  & .card-info-global {
    grid-area: 2/1/2/4;
  }

  & .card-info-container {
    grid-area: 3/1/3/4;
    display: grid;
    grid-template-columns: 30% 30% 30%;

    grid-gap: .5rem 0;

    & div.card-recipe-info-preparation-time {
      grid-area: 1/1/1/1;
    }

    & div.card-recipe-info-cooking-time {
      grid-area: 1/2/1/2;
    }

    & div.card-recipe-info-part-number {
      grid-area: 1/3/1/3;
    }

    & div.card-recipe-info-tag-name {
      grid-area: 2/1/2/3;
    }
  }


  & .card-subtitle {
    width: 70%;
    display: grid;
    grid-template-columns: 15% 75%;
  }

  & .card-subtitle>svg {
  }

  & .card-subtitle>div {
    text-align: left;
    padding-left: .3rem;
  }

  & .author-icon {

  }

  & h4.card-title {
    height: 1rem;
  }

  & .card-recipe-info {
    display: grid;
    grid-template-columns: 10% 90%;
    grid-row: 2;
    width: 100%;
    grid-gap: .5rem;

    & div {
      margin: auto 0;
    }

  }

  & .card-infos-value {
    text-align: left;
    padding-left: 1rem;
  }

  & .card-info-tag {
    text-align: left;
    padding-left: .5rem;
  }

  & .card-icons {
    width: 20px;
  }

  & div.card-body {
    padding: .5rem;
  }
}

.Recipe::-webkit-scrollbar-track {
  border-radius: 7px;
}

@media screen and ( max-width: $screen-md-min ) {
  .RecipesList {
  }
}

@media screen and (min-width: $screen-sm-min) and (max-height: $screen-md-min){
  .RecipesList {

    & .recipe-card {
      width: 19rem;
      margin-left: .5rem;
      height: 200px;
    }

    & .recipe-card:last-of-type {
    }
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .RecipesList {

    & .recipe-card {
      width: 20rem;
      margin-left: .5rem;
    }

    & .recipe-card:last-of-type {
    }

    & .card-recipe-info {
      display: grid;
      grid-template-columns: 40% 60%;
      width: 80%;
    }

    & .card-infos-name {
      text-align: left;
      padding-left: 2rem;
    }

    & .card-infos-value {
      text-align: left;
      padding-right: 1rem;
      width: max-content;
    }

    & .card-subtitle>svg {
      margin: 0;
      align-self: center;
    }

    & .card-recipe-info-tag-name {
      grid-template-columns: 20% 80%;
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .RecipesList {
    max-height: 700px;
  }
}

@media screen and ( min-width: $screen-xl-min) {

  .RecipesList {

    & .recipe-card {
      width: 30rem;
    }

    & .card-body {
      display: grid;
      grid-template-columns: 32% 32% 32%;

      & img.img-thumbnail {
        width: 80%;
        grid-area: 1/1/4/1;
        justify-self: center;
        align-self: center;
      }
    }

    & h3.recipe-card-header {
      grid-area: 1/2/1/4;
      height: 60%;
      padding: 0.8rem;
    }

    & .card-info-global {
      grid-area: 2/2/2/4;
      margin-left: 1rem;
    }

    & .card-info-container {
      grid-area: 3/2/3/4;
      margin-left: 1rem;
    }
  }
}

@media screen and ( min-width: $screen-xxl-min) {

  .RecipesList {

    //font-size: large;

    & .author-icon, div {
      //font-size: large;
    }

    & .recipe-card {
      width: 35rem;
    }

    & .card-body {
      display: grid;
      grid-template-columns: 32% 32% 32%;

      & img.img-thumbnail {
        width: 95%;
        grid-area: 1/1/4/1;
        justify-self: center;
        align-self: center;
      }
    }

    & h3.recipe-card-header {
      grid-area: 1/2/1/4;
      margin-bottom: 1rem;
    }

    & .card-info-global {
      grid-area: 2/2/2/4;
      margin-bottom: 1rem;

      & img, svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      & h4 {
        margin-bottom: 1rem;
      }
    }

    & .card-info-container {
      grid-area: 3/2/3/4;
      grid-template-columns: 49% 49%;

      & img, svg {
        width: 2rem;
      }

      & .card-recipe-info {
        margin: auto 0;
      }

      & div.card-recipe-info-preparation-time {
        grid-area: 1/1/1/1;
      }

      & div.card-recipe-info-cooking-time{
        grid-area: 2/1/2/1;
      }

      & div.card-recipe-info-part-number {
        grid-area: 1/2/1/2;
      }

      & div.card-recipe-info-tag-name {
        grid-area: 2/2/2/2;
      }
    }
  }
}

