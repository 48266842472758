@import '../../assets/scss/variables';

.PasswordReset {

  & .PasswordReset-title {
    font-size: $text-size-sm;
  }

  & .message {
    margin: 3rem auto 0 auto;
    min-height: 1.5rem;
    @include flex( center, center, row nowrap);
  }

  & .PasswordReset-form {
    margin: 2rem 0 4rem 0;
  }

  & .formInput-container {
    display: grid;
    grid-template-columns: 85% 10%;
    margin-bottom: 1.5rem;
  }

  & .formInput-container>label {
    grid-area: 1/1/1/1;
    margin-bottom: 1rem;
  }

  & .formInput-container>input {
    grid-area: 2/1/2/1;
    padding-left: .5rem;
    height: 2rem;
  }

  & .input-eyes {
    grid-area: 2/2/2/2;
    align-self: center;
    justify-self: center;
    transform: scale(1.5);
  }

  & .buttons {
    @include flex( center, space-evenly, row nowrap);
  }

  & .buttons>input {
    width: 45%;
    height: 2rem;
  }

  & .register-message {
  }
}





@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) {
  .PasswordReset {

    & .message {
      margin: 5rem auto 0 auto;
    }

    & .PasswordReset-title {
      font-size: $text-size-md;
    }

    & .PasswordReset-form {
      margin: 1rem 0 4rem 0;
    }

    & .formInput-container {
    }

    & .buttons {
    }
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .PasswordReset {

    & .message {
      margin: 9rem auto 2rem auto;
    }

    & .PasswordReset-form {
      margin-bottom: 8rem;
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .PasswordReset {

    & .message {
    }

    & .PasswordReset-title {
    }

    & .PasswordReset-form {
      margin: 1rem 0 4rem 0;
    }

    & .formInput-container {
    }

    & .buttons {
      margin-top: 4rem;
    }
  }
}


