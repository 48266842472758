@import '../../assets/scss/variables';

.AppTitle {
  height: 4vh;
  width: 100%;
  font-size: larger;
}






@media screen and ( max-width: $screen-md-min ) {
  .AppTitle {

    & .hide {
    }

    & div.hide-component {
    }
  }

}

@media screen and (min-width: $screen-sm-min) {
  .AppTitle {
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .AppTitle {

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }


}

@media screen and ( min-width: $screen-lg-min ) {
  .AppTitle {
    @include flex( center, flex-start, row nowrap);
    justify-self: center;
    font-weight: 500;
    font-size: xxx-large;

    & .user-name .user-log{
    }

    & .infos-user {
    }
  }
}


