@import "~bootstrap/scss/bootstrap";
@import '../src/assets/scss/variables';


.App {
  @include flex(center, center, column nowrap);
  height: 100%;

  & .fade {
    width: 100%;
  }
  & .modal.show .modal-dialog {
    margin-left: .4rem;
  }

  & .modal-content {
    background-color: #ddd;
    border-radius: 7px;
  }

  & .modal-content>div {
    border-bottom: 1px solid #eee
  }

  & .modal-header {
    align-items: center;
  }

  & .modal-header>h1 {
    font-size: x-large;
  }



  & .burger-menu {
  }

  & .component-container {
    height: 87%;
    width: 90%;
    overflow-y: overlay;
    margin-top: .5rem;
  }

  & .spinner-border {
    width: 5rem;
    height: 5rem;

  }

  & .loaderParent {
    justify-self: center;
    align-self: center;
  }

  & .component-container::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  & .component-container::-webkit-scrollbar-track {
    background: $component-bg-color;; /* color of the tracking area */
    border-radius: 7px;
  }

  & .component-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1); /* color of the scroll thumb */
    border-radius: 7px; /* roundness of the scroll thumb */
    border: 3px solid $component-bg-color;; /* creates padding around scroll thumb */
  }

  & .footer {
    margin: .5rem auto;
    left: auto;
    width: 100%;
  }
}



div.modal-footer {
  @include flex( center, center, column nowrap);

  & .add-recipe {
    @include flex( center, center, row nowrap);
    border-radius: 7px;
    border: none;
    background: $background-color;
    height: 2rem;
    padding: 0 .4rem;
    color: $text-color;
    text-decoration: none;

    & svg {
      margin-right: .5rem;
      transform: scale(1.5);
    }
  }
}


//Toastify
.Toastify__toast-container {

  & div {
    & .Toastify__toast--success {
      background: lightgreen;
    }

    & .Toastify__toast--error{

    }
  }
}




@media screen and (min-width: $screen-md-min) {
  .App {

    & .component-container {
      margin-top: 0;
    }

    & .spinner-border {
      width: 7rem;
      height: 7rem;
    }

    & .loaderParent {
    }

    & .burger-menu {
    }
  }

  .modal-dialog {
    max-width: 600px;
    margin: 2.5rem 0.3rem;
  }
}

@media screen and (min-width: $screen-lg-min) {

  .App {

    & .burger-menu {
    }

    & .modal-dialog {
      max-width: 600px;
      margin: 2.5rem 0;
    }

    & .menuBar {
      margin-top: .5rem;
      text-align: -webkit-center;
      text-align: -moz-center;
    }

    & .component-container {
      margin-top: 6rem;
      height: 77%;
    }

    & .footer {
      width: 100%;
    }

    & .add-recipe {
      position: absolute;
      top: 60%;
      right: 5%;
      transform:  translate(-0%,50%);
      border-radius: 40px;
      padding: 10px;
      text-decoration-color: white;
      @include flex( center, center, row nowrap);
      height: 3.5rem;
      width: 3.5rem;
      background: $sub-component-bg-color;

      & .add-recipe-img {
        color: $text-color;
        text-decoration: none;
      }

      & svg {
        transform: scale(2.2);
        background: #CECECE;
        border-radius: 40px;
      }
    }
  }
  //Toastify
  .Toastify__toast-container {
    top: 8rem;

    & div {
      & .Toastify__toast--success {
        background: lightgreen;
      }

      & .Toastify__toast--error{

      }
    }
  }


}

@media screen and (min-width: $screen-xl-min) {
  .App {

    & .burger-menu {
    }

    & .modal-dialog {
    }

    & .menuBar {
    }

    & .component-container {
      height: 79%;
    }

    & .footer {
    }

    & .add-recipe {

      & .add-recipe-img {
      }

      & svg {
      }
    }
  }
}

@media screen and (min-width: $screen-xxl-min) {
  .App {

    & .burger-menu {
    }

    & .modal-dialog {
    }

    & .menuBar {
    }

    & .component-container {
      height: 79%;
    }

    & .footer {
    }

    & .add-recipe {

      & .add-recipe-img {
      }

      & svg {
      }
    }
  }
}





