@import '../../assets/scss/variables';

.PicturesList {

  & .img-item {
    display: grid;
    grid-template-columns: 35% 45% 10% 10%;
    align-items: center;
    justify-items: center;
    margin: .5rem 0;

    & img {
      width: 75px;
    }

    & .image-preview {
      grid-area: 1/1/1/1;
    }

    & .name-confirm {
      grid-area: 1/2/1/2;
    }

    & .button-1 {
      grid-area: 1/3/1/3;
      transform: scale(1.5);
    }

    & .button-2 {
      grid-area: 1/4/1/4;
      transform: scale(1.5);
    }

  }
}