@import '../../assets/scss/variables';

.Footer {
  width: 90%;
  margin: auto;

  & .footer-container {
  }

  & .favicon-attribute {
    opacity: 0;
  }

  & .creator {
    text-align: end;
  }
}


@media screen and ( min-width: $screen-md-min ) {

  .Footer {
    width: 90%;
    height: 1.5rem;
    margin: auto;
    border-radius: 7px;

    & .footer-container {
      @include flex( center, space-between, row nowrap);
      height: 100%;

      padding-right: 1rem;
    }

    & .favicon-attribute {
      opacity: 1;
    }

    & .creator {
      padding: .05rem;
      align-content: center;
      border-radius: 5px;

    }

    & .favicon-attribute {
      padding-left: 1rem;
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .Footer {
    background: $sub-component-bg-color;

    & .footer-container {
    }

    & .creator {

    }
  }
}