@import '../../assets/scss/variables';

.ConfirmeEmail {

  & .welcome-title {
    margin-top: 3rem;
    font-size: $text-size-sm;
  }

  & .welcome-content {
    margin-top: 8rem;
  }

  & .welcome-content>div {
    @include flex( center, space-evenly, column nowrap);
    margin-bottom: 1rem;
  }

  & .message {
    margin: auto;
    width: fit-content;
    padding: .2rem;
    margin-bottom: 2rem;
  }
  & p.successfulRegister {
    margin-bottom: 2rem;
  }
}



@media screen and ( min-width: $screen-sm-min ) {
  .ConfirmeEmail {

    & .welcome-title {
      margin-top: 7rem;
    }

    & .welcome-message {
    }

    & p {
    }

    & .message, a {
      font-size: x-large;
    }
  }
}


@media screen and ( min-width: $screen-md-min ) {
  .ConfirmeEmail {

    & .welcome-title {
      margin-top: 10rem;
      font-size: $text-size-md;
    }

    & p.successfulRegister {
      font-size: x-large;
    }
  }
}

@media screen and ( min-width: $screen-lg-min ) {
  .ConfirmeEmail {

    & .welcome-title {
      margin-top: 12rem;
    }
  }
}