@import '../../assets/scss/variables';

.Recipe {
  @include flex(flex-start, center, row wrap);


  //overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  border-radius: 7px;
  height: auto;
  width: 100%;

  & .one-recipe-card {
    width: 20rem;
    //height: 170px;
    height: inherit;
    margin-bottom: 3rem;
    //overflow: hidden;
  }


  & h3.recipe-card-header {
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 10% 70% 10% 10%;
    padding: .85rem;

    & .recipe-card-name {
      grid-area: 1/2/1/2;
    }

    & svg {
      align-self: center;
      justify-self: center;
      transform: scale(1.3);
    }

    & .edit-pencil {
      grid-area: 1/4/1/4;
    }

  }

  & #recipe_description {
    width: 90%;
  }

  & #recipe_name {
    width: 100%;
  }

  & .edit-pencil {
    align-self: center;
    justify-self: center;
    transform: scale(1.4);
    grid-area: 1/3/1/3;
  }

  & .spacer {
    border-bottom: 1px solid #eee;
    padding-bottom: .5rem;
    margin-bottom: .5rem;
  }

  & .card-info-container {
    display: grid;
    grid-template-columns: 30% 30% 30%;

    grid-gap: .5rem 0;

    & div.card-recipe-info-preparation-time {
      grid-area: 1/1/1/1;
    }

    & div.card-recipe-info-cooking-time {
      grid-area: 1/2/1/2;
    }

    & div.card-recipe-info-part-number {
      grid-area: 1/3/1/3;
    }

    & div.card-recipe-info-tag-name {
      grid-area: 2/1/2/3;
    }
  }

  & .card-subtitle {
    width: 70%;
    display: grid;
    grid-template-columns: 15% 75%;
    //margin: auto;
  }

  & .card-subtitle > svg {
    margin: auto;
  }

  & .card-subtitle > div {
    text-align: left;
    padding-left: .3rem;
  }

  & .author-icon {

  }

  & .card-recipe-info {
    display: grid;
    grid-template-columns: 10% 90%;
    grid-row: 2;
    width: 100%;
    grid-gap: .5rem;

    & div {
      margin: auto 0;
    }

  }

  & .card-infos-value {
    text-align: left;
    padding-left: 1rem;
  }

  & .card-info-tag {
    text-align: left;
    padding-left: .5rem;
  }

  & .card-icons {
    width: 20px;
  }

  & div.card-body {
    padding: .5rem;
  }

  & .card-header-tabs {
    margin-left: 0.1rem;
  }

  & .tab-content {
    margin-top: 1rem;
  }

  & #Ingredients-tabpane-ingredients {
  }

  & .ingredient {
    margin-bottom: .5rem;
    display: grid;
    grid-template-columns: 28% 28% 28% 7% 7%;

    & .ingredient-trash {
      align-self: center;
      justify-self: center;
      grid-area: 1/4/1/4;
    }

    & .edit-ingredient-quantity {
      text-align-last: end;
    }

    & .ingredient-name, .ingredient-unit {
      justify-self: left;
      padding-left: 1rem;
    }

    & .ingredient-name {
      grid-area: 1/1/1/1;
    }

    & .ingredient-unit {
      grid-area: 1/3/1/3;
    }

    & .ingredient-quantity {
      grid-area: 1/2/1/2;
      justify-self: right;
      padding-right: 1rem;

      & .edit-ingredient-quantity > input {
        width: 70%;
      }
    }

    & .edit-pencil {
      grid-area: 1/5/1/5;
    }

    & .submit-change {
      grid-area: 1/5/1/5;
    }

    & .cancel-change {
      grid-area: 1/4/1/4;
    }
  }

  & .ingredient-header {

    & .add-ingredient {
      grid-area: 1/3/1/3;
    }

    & .submit-change {
      grid-area: 1/4/1/4;
    }

    & .cancel-change {
      grid-area: 1/5/1/5;
    }
  }

  & .instructions-list {
    width: 100%;
    text-align: left;
    padding-top: 1rem;
  }

  & .instruction {
    margin-bottom: .5rem;
    display: grid;
    grid-template-columns: 6% 77% 7% 7%;
    grid-gap: .2rem;

    & .add-instruction {
      grid-area: 1/2/1/2;
      transform: scale(1.5);
      justify-self: end;
      margin-right: 1rem;
    }

    & .submit-change {
      grid-area: 1/3/1/3;
    }

    & .cancel-change {
      grid-area: 1/3/1/3;
    }

    & .edit-pencil {
      grid-area: 1/4/1/4;
    }

    & .instruction-description {
      grid-area: 1/2/1/2;

      & #instruction_description {
        width: 100%;
      }
    }

    & .instruction-trash {
      align-self: center;
      justify-self: center;
      grid-area: 1/3/1/3;
    }

    & .deleting {
      text-decoration: line-through;
    }
  }

  & .instruction-header {

    & .cancel-change {
      grid-area: 1/4/1/4;
    }
  }

  & nav > a.nav-item {
    color: $text-color;
    text-decoration: none;
  }

  & .edit-recipe-name {

  }

  & input, select, textarea {
    background: #ddd;
  }


  & #recipe_preparation_time, #recipe_cooking_time, #part_number, #tag_name {
    width: 60%;
    padding-left: .3rem;
  }

  & .add-ingredient {
    align-self: center;
    justify-self: end;
    margin-right: 1rem;
    transform: scale(1.5);
    grid-area: 1/4/1/4;
  }

  & .submit-change {
    align-self: center;
    justify-self: center;
    transform: scale(1.5);
  }

  & .cancel-change {
    align-self: center;
    justify-self: center;
    transform: scale(1.5);
  }

  & .deleting {
    text-decoration: line-through;
  }

  & .dragndrop {
    align-self: center;
    justify-self: center;
    width: 20px;
    //transform: scale(1.2);
    grid-area: 1/1/1/1;
    //transform: rotate(90deg);
  }

  & .message {
    font-size: large;
    margin-top: 10rem;
    padding: .5rem;
  }

}

.confirm-modal-body, .add-ingredient-modal-body {
  @include flex(center, space-evenly, row nowrap);
  margin-top: 1rem;
  width: 100%;
}

div.modal-footer {
  @include flex(center, center, row nowrap);

}

label.form-label {
  margin-bottom: .3rem;

}


@media screen and (max-width: $screen-md-min) {

}

@media screen and (min-width: $screen-sm-min) and (max-height: $screen-md-min) {
  .Recipe {

    & .one-recipe-card {
      margin-bottom: 3rem;
    }

    & .instruction {
      margin-bottom: 1rem;
    }

    & .instruction-description {

      & #instruction_description {
      }
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .Recipe {

    & h3.recipe-card-header {
      //grid-template-columns: 95% 5%;
      grid-template-columns: 10% 70% 10% 10%;

      & .recipe-card-name {
        grid-area: 1/2/1/2;
      }

      & .edit-pencil {
        grid-area: 1/4/1/4;
      }

      & #recipe_name {
        width: 100%;
      }
    }


    & .card-info-container {
      display: grid;
      grid-template-columns: 40% 40%;
      padding-left: 2rem;
      grid-gap: .5rem 0;

      & div.card-recipe-info-preparation-time {
        grid-area: 1/1/1/1;
      }

      & div.card-recipe-info-cooking-time {
        grid-area: 2/1/2/1;
      }

      & div.card-recipe-info-part-number {
        grid-area: 1/2/1/2;
      }

      & div.card-recipe-info-tag-name {
        grid-area: 2/2/2/2;
      }
    }

    & .card-subtitle > svg {
      margin: 0;
      align-self: center;
    }

    & .card-subtitle {
      padding-left: 2rem;
      grid-template-columns: 10% 75%;
    }

    & .tab-content {
      width: 80%;
      margin: 2rem auto;
      //margin-top: 2rem;
    }

    & .ingredient {
      margin: .7rem 0;
      display: grid;
      grid-template-columns: 30% 30% 30% 5% 5%;

      & * {
        align-self: center;
      }

      & .ingredient-trash {
        grid-area: 1/4/1/4;
      }

      & .ingredient-name {
        grid-area: 1/1/1/1;
        padding-left: 0;
        align-self: center;
      }

      & .ingredient-quantity {
        grid-area: 1/2/1/2;
        justify-self: right;
        padding-right: 4rem;
      }

      & .ingredient-unit {
        grid-area: 1/3/1/3;
      }
    }

    & .instructions-list {
      padding-top: 2rem;
    }

    & .instruction {
      margin-bottom: 2rem;

      & .instruction-description {

        & #instruction_description {
        }
      }
    }

    & .message {
      font-size: x-large;
      margin-top: 20rem;
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .Recipe {
    width: 90%;
    height: 100%;

    & #recipe_preparation_time, #recipe_cooking_time, #part_number, #tag_name {
      width: 15%;
    }

    & nav.nav-tabs {
      margin-left: 0.5rem;
    }


    & div.tab-content {
      //height: 100%;
      width: 90%;

      & #Ingredients-tabpane-ingredients {
        opacity: 1;
        grid-area: 1/1/1/1;
        padding: .5rem 1rem;

        & .ingredient {
          grid-template-columns: 26% 20% 15% 7% 7%;
          grid-gap: 0.7rem;

          & .ingredient-quantity {
            padding-right: 0;
          }

          & .ingredient-name, .ingredient-unit {
            padding-left: 0;
          }
        }


      }



      & .active {
        font-weight: normal;
      }
    }

    & .card-info-tag {
      padding-left: 1rem;
    }

    & .card-infos-value {
      padding-left: .5rem;
    }

    & #tag_name {
      width: auto;
    }


    & .instructions-list {
      padding-top: 0;

      & .smooth-dnd-draggable-wrapper {
        overflow: visible;
      }
    }

    & .instruction {
      margin: 1rem 0;
      grid-gap: .7rem;

      & .instruction-description {

        & #instruction_description {
          width: 90%;
        }
      }
    }

    & .instruction-header {
    }


    & #quantity {
      min-width: 4rem;
    }
  }
}

@media screen and (min-width: $screen-xl-min) {
  .Recipe {
    width: 90%;

    & .ingredient {

      & .ingredient-name {
        //padding-left: 3rem;
      }
    }

    & .message {
      padding: 1rem;
    }

    & .main-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 5.2% 90%;
      margin-top: 2rem;
      //max-height: 25rem;

      & .ViewPictures {
        grid-area: 1 / 1 / 2 / 1;
      }

      & .ViewPictures.spacer {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      & .card-header-tabs {
        grid-area: 1 / 2 / 1 / 2;

        border-bottom: 1px solid #eee;
        padding-bottom: 2rem;
        margin-bottom: -0.8rem;
      }

      & .tab-content {
        grid-area: 2 / 2 / 2 / 2;
        margin: 0 auto;
      }
    }
  }
}

@media screen and (min-width: $screen-xxl-min) {
  .Recipe {

    align-items: flex-start;

    & .one-recipe-card {
      margin-bottom: 0;
    }
  }
}

