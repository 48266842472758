@import '../../assets/scss/variables';

.ViewPictures {
  width: 100%;
  margin: auto;

  & .carousel-root {
    width: 300px;
    margin: auto;
  }

  & div >.carousel-slider {
    width: 300px;
  }

  & .legend{
    display: none;
  }

  & .edit-button {
    display: grid;
    grid-template-columns: 90% 10%;
  }

  & #edit-pictures {
    grid-area: 1/2/1/2;
  }
}

#root > div > div.component-container.d-flex.align-items-start.justify-content-center > div > div > div > section > div.ViewPictures > div > div:nth-child(2) > div
{
  display: none;
}

@media screen and (min-width: $screen-xl-min) {
  .ViewPictures {

    & .control-dots {
      display: none;
    }

  }

  #root > div > div.component-container.d-flex.align-items-start.justify-content-center > div > div > div > section > div.ViewPictures > div > div:nth-child(2) > div
  {
    display: block;
    margin: 0;
  }
}

@media screen and (min-width: $screen-xxl-min) {
  .ViewPictures {

    & .carousel-root {

    }

  }


}
