@import '../../assets/scss/variables';

.UserAccount {
  width: 100%;

  & .account-title {
    font-size: $text-size-sm;

    & svg {
      margin-left: 1rem;
    }
  }

  & .account-form {
    margin-top: 2rem;
  }

  & .formInput-container {
    display: grid;
    grid-template-columns: 5% 80% 10%;
    grid-row: 2;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  & .formInput-container>label {
    grid-area: 1/2/1/2;
    text-align: start;
    padding-left: .5rem;
  }

  & .formInput-container>input {
    grid-area: 2/2/2/2;
    height: 2rem;
    border-radius: 7px;
    border: none;
    padding-left: .5rem;
  }

  & .formInput-container>input:disabled {
    background-color: #ddd0;
  }

  & .input-eyes {
    grid-area: 2/3/2/3;
    justify-self: center;
    align-self: center;
    transform: scale3d(1.6,1.6,1.6);
  }

  & .buttons {
    display: grid;
    grid-template-columns: 10% 41% 41%;
    gap: .5rem;
    height: 2rem;
    margin-top: 1.5rem;
  }

  & .buttons>input {
    width: 90%;
    height: 100%;
    //margin: 0 auto;
  }

  & .submit-input {
    grid-area: 1/2/1/2;
  }

  & .reset-input {
    grid-area: 1/3/1/3;
  }

  & div.spinner-border {
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
  }

  & .message {
    margin: auto;
    margin-top: 1rem;
    width: fit-content;
    padding: .2rem;
  }

}





@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) {
  .UserAccount {

    & .account-form {
      margin-top: 2.5rem;
    }

    & .formInput-container {
      margin-bottom: 1.5rem;
    }

    & .buttons {
      margin-top: 3rem;
    }
  }

}

@media screen and ( min-width: $screen-md-min ) {
  .UserAccount {

    & .account-title {

      & svg {
        margin-left: 2rem;
      }
    }

    & .account-form {
      margin-top: 4rem;
    }

    & .formInput-container {
    }

    & .UserAccount>h1 {
      font-size: $text-size-md;
    }

    & .buttons {
      height: 2.5rem;
      margin-top: 1.5rem;
    }

    & .formInput-container>input {
      height: 2.5rem;
      width: 50%;
      margin: auto;
    }
  }



}

@media screen and ( min-width: $screen-lg-min ) {
  .UserAccount {

    & .account-title {

      & svg {
      }
    }

    & .UserAccount {
      width: 80%;
      margin: 2rem;
    }
    & .account-form {
      width: 60%;
      margin: 3rem auto;
    }

    & .formInput-container {
      display: grid;
      grid-template-columns: 25% 55% 15%;
      grid-row: 1;
      gap: 1rem;
    }

    & .formInput-container>label {
      grid-area: 1/1/1/1;
      text-align: start;
      padding-left: 6.5rem;
      align-self: center;
    }

    & .formInput-container>input {
      grid-area: 1/2/1/2;
      height: 2rem;
      border-radius: 7px;
      border: none;
      padding-left: .5rem;
      width: 60%;
      margin-left: 8rem;
    }

    & .input-eyes {
      grid-area: 1/3/1/3;
      justify-self: flex-start;
      align-self: center;
      transform: scale3d(1.6,1.6,1.6);
    }

    & .buttons {
      margin-top: 6rem;
    }
  }

}


