@import '../../assets/scss/variables';

.Login {

  & .login-title {
    font-size: $text-size-sm;
  }

  & .login-form {
    margin: 2rem 0 4rem 0;
  }

  & .formInput-container {
    display: grid;
    grid-template-columns: 80% 10%;
    grid-row: 2;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  & .formInput-container>label {
    grid-area: 1/1/1/1;
    text-align: start;
    padding-left: .5rem;
  }

  & .formInput-container>input {
    grid-area: 2/1/2/1;
    height: 2rem;
    border-radius: 7px;
    border: none;
    padding-left: .5rem;
  }

  & .input-eyes {
    grid-area: 2/2/2/2;
    justify-self: center;
    align-self: center;
    transform: scale3d(1.6,1.6,1.6);
  }

  & .buttons {
    display: grid;
    grid-template-columns: 45% 45% 10%;
    height: 2rem;
    margin-top: 1.5rem;
  }

  & .buttons>input {
    width: 90%;
    height: 100%;
  }

  & .register-message {
    margin-bottom: 3rem;
  }

  & div.spinner-border {
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
  }

  & .message {
    margin: auto;
    margin-top: 1rem;
    width: fit-content;
    padding: .2rem;
  }

}




@media screen and ( max-width: $screen-md-min ) {

}

@media screen and (min-width: $screen-sm-min) {
  .Login {

    & .login-title {
      font-size: $text-size-md;
    }

    & .login-form {
      margin: 6rem 0 4rem 0;
    }

    & .formInput-container {
      margin-bottom: 1.5rem;
    }

    & .buttons {
      margin-top: 3.5rem;
    }
    & .message {
      font-size: x-large;
    }
  }
}

@media screen and ( min-width: $screen-md-min ) {
  .Login {

    & .login-form {
      margin-bottom: 8rem;
    }
  }
}



@media screen and ( min-width: $screen-lg-min ) {
  .Login {

    height: 100%;

    & .login-title {
      margin-top: 3rem;
    }

    & .login-form {
      margin: 6rem 0 4rem 0;
    }

    & .formInput-container {
      margin-bottom: 1.5rem;
    }

    & .buttons {
      margin-top: 3.5rem;
    }

    & .message {
      font-size: xx-large;
    }
  }


}


