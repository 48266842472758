@import './reset';
@import './variables';

@import url('https://fonts.googleapis.com/css?family=Roboto');


html {
  background-color: #aaa;
	font-family: 'Roboto', sans-serif;
}

body {
  background-image: linear-gradient(to bottom , $background-color , #aaa);
  width: 100vw;
  height: 100vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
 // hide scrollbar for chrome
body::-webkit-scrollbar {
  display: none;
}

#root {
  width: 100vw;
  height: 100vh;
}

.active {
  font-weight: bold;
}

a {
  color: #004fc1;
  text-decoration: underline;
}

a:hover {
  color: hotpink;
  text-decoration: underline;
}

input, select, textarea {
  border: none;
  border-radius: 7px;
}

